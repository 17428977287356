.launch-pad {
    .line-input {
        width: 100% !important;
        background-color: var(--color-background-inset);
        padding: var(--size-spacing-md);
        border-radius: 3px;
    }
    .node-select-option {
        padding: var(--size-spacing-sm) var(--size-spacing-md);
    }
}