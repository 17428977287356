.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  z-index: var(--z-index-modal);
  background-color: rgba(0, 0, 0, 0.468);
}

.modal {
  position: fixed;
  top: 10vh;
  left: 10vw;
  right: 10vw;
  min-height: 10vh;
  
  background-color: var(--color-background);
  padding: var(--size-spacing-lg) var(--size-spacing-xl) 70px;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: var(--size-spacing-xs) var(--size-spacing-xs);
  font-weight: 900;
  cursor: pointer;
}

.input-with-button-container {
  padding: var(--size-spacing-xs) 0;
  display: flex;
}
