@mixin prevent-select{
    // prevent this text from getting selected
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

$breakpoint-desktop: 768px;

// _mixins.scss
@mixin for-desktop {
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
}

:root {
    // complete black has an interesting effect where the screen of a phone blends in.
    --color-background: rgb(0, 0, 0);
    --color-text: rgb(198 198 187);
    --color-text-inactive: rgb(123, 123, 113);
    --color-label-text:  rgba(241, 241, 227, 0.3);
    --color-background-inset:  rgb(50, 50, 50);
    --color-text-highlight: rgba(210, 139, 207, 0.8);
    --color-label: #fff;
    --color-line: white;
    --color-debug: grey;
    --color-border:  #939292;
    --color-button-default:hsl(55, 5%, 48%);
    --color-background-distinct:hsl(55, 5%, 11%);

    --font-family-default: sans-serif;
    --font-family-user-text: Courier, monospace;

    --size-spacing-xs:3px;
    --size-spacing-sm:5px;
    --size-spacing-md:8px;
    --size-spacing-lg:10px;
    --size-spacing-xl:15px;
    --size-spacing-xxl:20px;
    --size-spacing-xxxl:50px;

    --size-text-xs: 12px;
    --size-text-sm: 14px;

    --size-icon-xs: 12px;
    --size-icon-sm: 14px;
    --size-icon-md: 26px;

    --size-button-sm: 22px;
    --size-button-md: 30px;
    --size-height-action-bar: 60px;
    --size-height-action-bar-padding: 20px; // factor in the padding for the iphone bevel

    --border-default: 1px solid var(--color-border);
    --size-border-radius: 10px;
    --size-border-radius-inset: 9px;

    --size-line:2px;
    --size-hr: var(--size-line) * 2;
    --size-headline: var(--size-lines) * 1.2;
    --size-lines: 18px;
    --size-line-action-button: 15px;

    --width-max: 700px;

    --z-index-modal:4;
    --z-index-overlay:3;
    --z-index-action-bar:2;
    --z-index-dropdown:1;
}