/*
This whole system is used to make a text area
automatically resize to fit its contents.
We accomplish this by having a hidden div under the text area
that mirrors the text area's contents, and then
we set the text area's height to match the height of the hidden div.
*/

.line-input-container {
    position: relative; // Make sure the line input and sizer divs are positioned relatively to this container
    display: grid; // Ensure the height of this div are the same as the line input

    &.has-button {
        .line-input {
            padding-right: var(--size-icon-md);
        }
    }
    .line-input-button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            &:hover{
                background-color: var(--color-background-hover);
            }
            &:focus {
                background-color: var(--color-text);
                color: var(--color-background);
                border: None;
                outline: None;
            }
        }
    }
}
.line-input {
    // remove text area styling
    font-family: inherit; /* Use the same font as the rest of the page */
    font-size: inherit; /* Use the same font size as the rest of the page */
    resize: none; /* Prevent resizing */
    border: none; /* Remove the border */
    outline: none; /* Remove the outline */
    overflow: hidden; /* Hide the scrollbar when not needed */
    box-shadow: none; /* Remove box-shadow */
    -webkit-appearance: none; /* Remove default appearance on WebKit browsers */
    -moz-appearance: none; /* Remove default appearance on Mozilla browsers */
    appearance: none; /* Remove default appearance */
    width: auto;
    
    // add line input styling
    min-height: 1em;
    line-height: 1.2em;
    color: var(--color-text);
    white-space: pre-wrap; /* Preserve line breaks and whitespace */
    word-wrap: break-word; /* Break words if they don't fit */
    
    min-width: 20px;
    padding:var(--size-spacing-xs);
    box-sizing: border-box; /* Ensure padding is included in the height calculations */
    outline-color:var(--color-label);
    background-color: var(--color-background);
    &:focus{
        outline:None;
    }
    font-family: var(--font-family-user-text);
    // &:empty:before{
    //     content:attr(data-placeholder);
    //     color: var(--color-text-inactive);
    //     font-weight: 100;
    //     font-style:italic;
    // }

    // TESTING
    // background-color: red;
    &.line-input-sizer{
        position: absolute; /* Make sure the sizer div doesn't affect the page layout */
        visibility: hidden; /* Hide the sizer div */
        top: 0;
        left: 0;
    }
}