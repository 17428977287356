.tag {
    border: var(--border-default);
    border-radius: var(--size-border-radius);
    display: flex;
    align-items: center;
    // padding: 0 var(--size-spacing-sm);
    
    &:last-child {
        margin-right: 0;
    }
    &.needs-selection {
        border-color: var(--color-text-highlight);
    }

    .dropdown-container{
        position: relative;
        &.is-open {
            width: 250px;
            .line-input {
                width: 100%;
            }
        }
    }
    .node-select-input{
        position: relative;
        margin-right: var(--size-spacing-sm);
        .line-input-container {
            background-color: var(--color-background);
        }
        &.is-open {
            width: 250px;
            .line-input-container {
                background-color:  var(--color-background-inset);
            }
            .line-input {
                width: 100%;
            }
        }
    }
    .dropdown-container {
        width: 100px;
    }

    .node-select-options, .dropdown{
        position: absolute;
        background-color: var(--color-background);
        border: var(--border-default);
        border-top-width: 0px;
        left:0;
        right:0;
        z-index: var(--z-index-dropdown);
    }
    .dropdown {
        display: flex;
        flex-direction: column;
    }
    .dropdown-option {
        width: 100%;
    }
    .node-select-option {
        padding: var(--size-spacing-xs) var(--size-spacing-md); 
        font-size: 1em;
    }
    .line-input {
        font-size: 0.9em;
    }

}