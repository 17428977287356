

.action-bar {
    z-index: var(--z-index-action-bar);
    position: fixed;
    left: 0;
    right: 0;
    bottom:0;
    transform: translateY(-100%);
    height: var(--size-height-action-bar);
    
    background-color: var(--color-background-distinct);
    color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: stretch; // have the buttons float at the top
    gap: var(--size-spacing-xs);
    padding:  var(--size-spacing-xs) var(--size-spacing-xs) var(--size-height-action-bar-padding);


    &.keyboard-visible {
        height: 60px;
        padding-bottom: var(--size-spacing-sm);
    }
    
    // transition: all 0.1s ease-in-out;
}


.action-button {
    flex-grow:1;

    button {
        width: 100%;
        label {
            font-size: var(--size-text-xs);
        }
    }
}