@import "css/variables";

.connection {
    @include  prevent-select;
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    text-align: center;
}