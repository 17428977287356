.calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 200px;
    margin: var(--size-spacing-xs);

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    
    .calendar-day, .calendar-weekday, .calendar-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: var(--size-lines);
        width: var(--size-lines);
        margin: var(--size-spacing-xs);
        button {
            margin: 0;
            padding: 0;
            &.selected {
                color: var(--color-background);
                background-color: var(--color-text-highlight);
                border-radius: var(--size-lines);
            }
        }
        &.selectable{
            cursor: pointer;
        }
    }
    .calendar-weekday {
        font-size: var(--size-text-sm);
        font-weight: bold;
        color: var(--color-text-inactive);
    }
    .calendar-month {
        display: flex;
        justify-content: center;
        color: var(--color-text-inactive);
        font-weight: bold;
        font-size: var(--size-text-md);
    }
}

