@import "css/variables";

$card-color:none;
$card-border:none;

@mixin card {
    border-left-width: $card-border;
    border-right: $card-border;
    background-color: $card-color;
    padding: 3px 7px;
}

.editor-line{
    display: flex;

    // prevent the line from being too wide
    max-width: var(--width-max);
    margin: 0 auto;
    
    

    &:focus-visible {
      outline:None;
    }
    .editor-line-input{
        width:100% !important;
        font-size:var(--size-lines);
        font-weight: 900;
        padding-top: var(--size-spacing-md);
        padding-bottom: var(--size-spacing-sm);
        word-break: break-word;

        // bond.kind == subgroup
        &.sg {
        color: var(--color-text-highlight)
        }
    }
}

$indent: 1px;

@mixin level-base {
    border: 0px solid var(--color-text-highlight);
}

.level-1 {
    @include level-base;
    border-left-width: $indent;
}
.level-2{
    @include level-base;
    border-left-width: $indent * 2;
}
.level-3{
    @include level-base;
    border-left-width: $indent * 3;
}
.level-4{
    @include level-base;
    border-left-width: $indent * 4;
}
.level-5{
    @include level-base;
    border-left-width: $indent * 5;
}
.level-6{
    @include level-base;
    border-left-width: $indent * 6;
}

.nesting-line {
    border-left: 9px solid var(--color-background);
    border-right: 5px solid var(--color-background);
    background-color: var(--color-label-text);
    width: 2px;
    flex-shrink: 0;
}

.editor-line-content {
    flex-grow: 1;
}

.line-tag-container {
    flex-grow:1;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    gap:var(--size-spacing-sm) var(--size-spacing-sm)
}

.columns {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;

    & > .branch {
        max-width: var(--width-max);
        min-width: min(400px, 95%);
        border-left: 1px solid var(--color-label-text);
        padding: var(--size-spacing-sm) var(--size-spacing-md);
    }
}