.selector-container {
    position: relative;
    .selector-dropdown {
        position: absolute;
        z-index: var(--z-index-dropdown);
        top: calc(var(--size-spacing-md) * -1);
        left: 0;
        background-color: var(--color-background-distinct); 
        color: var(--color-text);
        padding: var(--size-spacing-md);
        border-radius: var(--size-spacing-sm);
    }
    .selector-option{
        cursor: pointer;
    }
    .spacer{
        padding: 0 1em;
    }
}