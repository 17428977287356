
.node-select-option {
    font-size: 1.1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    & > div {
        display: flex;
        align-items: center;
    }
    &:hover {
        background-color: var(--color-background-inset);
    }
    &.selected {
        background-color: var(--color-text);
        color: var(--color-background);
        border: None;
        outline: None;
    }
}
