@import "css/variables";

body {
  color: var(--color-text);
  background-color: var(--color-background);
}
h1 {
  font-size: 1.5em;
}
h2 {
  font-size: 1.2em;
}

button {
  cursor: pointer;
}
.editor-app{
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-y:scroll;
}
.navbar{
 position: fixed;
 top:0;
 left:0;
 right:0;
}

.view-container {
  
  &.is-mobile {
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  
  .view-window {
    font-family: var(--font-family-default);
    padding: 0 var(--size-line-action-button);
    margin:var(--size-spacing-xxxl) auto 50vh;
  }
}

hr {
  background-color: var(--color-line);
  border: 1px solid var(--color-line);
  border-radius: 7px 7px 7px 7px;
  height: 1px;
  margin-bottom: 10px;
}
.headline-block{
  .line-input{
    font-size: var(--size-headline);
    font-weight: 900;
  }
}

.debug {
  color:var(--color-debug);
  font-size: .8em;
}

.label-container{
  margin: 3px 0 3px;
  display: flex;
  justify-content:left;
  flex-wrap: wrap;
  align-items: start;
  gap: var(--size-spacing-sm);

  .label-title{
    font-size: .8em;
    font-weight: 900;
    margin-top: 4px;
    @include  prevent-select;
  }
  input, button, .divider, .line-input{
    background: none;
    border: none;
    color: var(--color-text-inactive);
    font-weight: bold;
  }
  .divider {
    margin-right:2px;
  }
  input {
    padding: 1px 5px
  }
  button {
    font-size: var(--size-text-sm);
    padding: 1px 5px;
    border-radius: var(--size-border-radius-inset);
    
    min-height: var(--size-button-sm);
    min-width: var(--size-button-sm);
    
    cursor: pointer;
    &:focus {
      background-color: var(--color-text);
      color: var(--color-background);
      border: None;
      outline: None;
    }
    @include  prevent-select;
    .icon {
      width: var(--size-icon-sm);
      height: var(--size-icon-sm);

      @include for-desktop{
        height: var(--size-icon-xs);
        width: var(--size-icon-xs);

      }
      display: flex;
    }
  }
  .node-select-input {
    min-width: 25px;
    text-align: left;
    display: block;
  }
}
.node-select-input .line-input-container {
  background-color: var(--color-background-inset);
  padding: 0 var(--size-spacing-sm);
  border-radius: 3px;
}

.overlay {
  position: fixed;
  top:0;
  left:0;
  right:0;
  min-height:100vh;
  background-color: var(--color-background);
  z-index: var(--z-index-overlay);
  bottom: 0;
  overflow-y: scroll;
  .node-select-option {
    padding: 5px
  }
}

.content{
  padding: var(--size-spacing-md);
  max-width: var(--width-max);
  margin: 0 auto;
}
.splash {
  text-align: center;
  h2 {
    font-family: var(--font-family-user-text);
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
}

button {
  &.lg {
    font-size: var(--size-text-lg);
  }
}
a {
  color: var(--color-text);
  &:hover {
    color: var(--color-text);
  }
}

.nav{
  position: fixed;
  top:0;
  left:0;
  
  .line-action-button{
    position: absolute;
    background-color: var(--color-background);
    color:var(--color-text);
    font-weight: bold;
    border: none;
    font-size: 1.1em;
    padding:7px;
  }
}

.pointer {
  cursor: pointer;
}

.button-default {
  background-color: var(--color-background-distinct);
  color:var(--color-button-default);
  border: none;
  padding:7px;
  // border-radius: 3px;
  cursor: pointer;

  @include  prevent-select;
  
  .icon {
    width: var(--size-icon-md);
    height: var(--size-icon-md);
  }
}

.max-width-wrapper {
  max-width: var(--width-max);
  margin: 0 auto;
}